// Projects.js
import React, { useState } from "react";
import {
  workImgOne,
  workImgTwo,
  workImgFour,
  workImgFive,
  workImgSix,
  workImgSeven,
} from "../../assets";
import Title from "../home/Title";
import ProjectsCard from "./ProjectsCard";
import Modal from "./Modal";
import I2DE from "../../assets/work/I2DE_WARRINER_Harry.pdf"
import materialise from "../../assets/work/MATERALISE_Harry_Warriner.pdf"
import reveng from "../../assets/work/REVENG_Harry_Warriner2.1.pdf"
import HCD1 from "../../assets/work/HCD_Warriner_Harry.pdf"
import Bouncer from "../../assets/work/Bouncerpdf.pdf"

const Projects = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});

  const handleCardClick = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div>
      <Title title="Recent" subTitle="Projects" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 lgl:gap-10 px-6">
      <ProjectsCard
          title="Data Sciene"
          category="Word"
          image={workImgSeven}
          description="Alzehimers"
          downloadLink="#" // Example download link
          handleClick={handleCardClick}
          showOverlay={true}
        />
        <ProjectsCard
          title="HCDE Part 2"
          category="InDesign"
          image={workImgSeven}
          description=""
          downloadLink="/path/to/blog-website-file.zip" // Example download link
          handleClick={handleCardClick}
          showOverlay={true}
        />
        <ProjectsCard
          title="Bouncer"
          category="LaTex"
          image={workImgSix}
          description="In this experiment, predictions are made for how a ball will move along an inclined tube. To determine
the horizontal distance to the location of the second bounce from the lower end of the tube"
          downloadLink={Bouncer}
          handleClick={handleCardClick}
          showOverlay={true}
        />
        <ProjectsCard
          title="HCDE Part 1"
          category="InDesign"
          image={workImgFive}
          description="KITCHEN GADGETS FOR
A UNIVERSITY KITCHEN"
          downloadLink={HCD1}
          handleClick={handleCardClick}
          showOverlay={true}
        />
        <ProjectsCard
          title="REVENG"
          category="LaTex"
          image={workImgFour}
          description="The reverse Engineering report of a CarWaxing Polishing handheld machine"
          downloadLink={reveng}
          handleClick={handleCardClick}
          showOverlay={true}
        />
        <ProjectsCard
          title="Materialise"
          category="LaTex"
          image={workImgTwo}
          description="The process taken to pick and analysis materials for a screwdriver, using ashby plots, and mathematical equations."
          downloadLink={materialise}
          handleClick={handleCardClick}
          showOverlay={true}
        />
        <ProjectsCard
          title="I2DE"
          category="InDesign"
          image={workImgOne}
          description="Research into water resiliance. Watertight"
          downloadLink={I2DE} 
          handleClick={handleCardClick}
          showOverlay={true}
        />
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} content={selectedProject} />
    </div>
  );
};

export default Projects;