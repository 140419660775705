import React from "react";
import ResumeTitle from "./ResumeTitle";
import { MdWork } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <div className="nes-container is-dark with-title">
      <p className="title">Education</p>
      <div className="w-full grid grid-cols-9 gap-4 px-6">
        <div className="col-span-9 md:col-span-4">
          <ResumeTitle title="Experience" icon={<MdWork className="nes-icon is-medium" />} />
          <ResumeCard
            badge="2021- Current"
            title="3D - Artist"
            subTitle="Freelance"
            des="3D Artist fullfilling requests for clients"
          />
          <ResumeCard
            badge="2018- Current"
            title="Web Developer"
            subTitle="Freelance"
            des="Designed and Coded many websites"
          />
        </div>

        <div className="w-full h-full hidden lgl:flex justify-center items-center">
          <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
        </div>

        <div className="col-span-9 md:col-span-4">
          <ResumeTitle title="Education" icon={<GiGraduateCap className="nes-icon is-medium" />} />
          <ResumeCard
            badge="2023 - Current"
            title="Imperial College London"
            subTitle="UK - London"
            des="Second year student, Projects highlighted in projects tab"
          />
          <ResumeCard
            badge="2018 - 2023"
            title="Radley College"
            subTitle="UK - Oxford"
            des={
              // <ul className="list-disc pl-6">
              //   <li>A‑Levels: A* Mathematics, A* Design Technology, A Physics</li>
              //   <li></li>
              //   <li></li>
              // </ul>
            <>A-Levels and GCSEs. <br /><br />Did many projects from TedX Videos to building a prize winning autonomous robot. Further projects highlighted in project section.</>
            }
          />
          <ResumeCard
            badge="2013 - 2018"
            title="Dragon School"
            subTitle="UK - Oxford"
            des="To be filled."
          />
        </div>
      </div>
    </div>
  );
};

export default Education;
