import React from "react";
import Title from "../home/Title";
import BlogCard from "./BlogCard";

// Import all assets
import {
  
  snowman,
  hologramDiamond,
  fluffyCat,
  halloweenPika,
  nightCity2,
  forestRobot,
  rainSmile,
  birdEyePlane,
  mineshaft,
  galaxySnorlax,
  blackHole,
  swimmingToaster,
  dancing,
  hallwayAlien,
  car,
  polyCornerStore,
  building,
  what,
  animeTemple,
  pacman,
  mug,
  halloween,
  tenseiSlime,
  halloweenCynda,
  kaws,
  halloweenPump,
  animeSky,
  rain,
  toonBedroom2,
  rollingBall,
  screw,
  hexagon,
  office,
  tubes,
  toonBedroom,
  animeRoad,
  abstractShapes3,
  asciiStormtrooper,
  swirl,
  hand,
  skull,
  toonArcade,
  interiorTardis,
  bench,
  killerLego,
  paint,
  shapes,
  nightCity,
  lightRays,
  circuit,
  dna,
  spider,
  fluffBalls,
  wrap,
  tardis,
  abstractShape2,
  abstractSphere,
  earth,
  abstractShapes,
  cube,
  donut,
} from "../../assets";

const Blog = () => {
  return (
    <div>
      <Title title="Latest" subTitle="3D Modelling projects" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 lgl:gap-10 px-6">
        <BlogCard image={snowman} subTitle="Snowman" title="Dec 1, 2022" category="holidays" link="https://www.instagram.com/p/Clo75N4Lly3/" />
        <BlogCard image={hologramDiamond} subTitle="HologramDiamond" title="Oct 31, 2022" category="holidays" link="https://www.instagram.com/p/CkZDUIyr-j6/" />
        <BlogCard image={halloweenPika} subTitle="Halloween" title="Oct 31, 2022" category="" link="" />
        <BlogCard image={fluffyCat} subTitle="Pumpkin Cat" title="Oct 27, 2022" category="realistic" link="https://www.instagram.com/p/CkNmkwFsbfv/" />
        <BlogCard image={forestRobot} subTitle="ForestRobot" title="Oct 19, 2022" category="realistic" link="https://www.instagram.com/p/Cj5sNNqs-c4/" />
        <BlogCard image={nightCity2} subTitle="Neon Street" title="Oct 16, 2022" category="realistic" link="https://www.instagram.com/p/CjxptTWsQR3/" />
        <BlogCard image={rainSmile} subTitle="Forgotten" title="Oct 11, 2022" category="realistic" link="https://www.instagram.com/p/CjlQJxVsKq_/" />
        <BlogCard image={birdEyePlane} subTitle="Flying from above" title="Aug 30, 2022" category="realistic" link="https://www.instagram.com/p/Ch5D1l3MdjS/" />
        <BlogCard image={mineshaft} subTitle="Abandoned mineshaft" title="Aug 24, 2022" category="realistic" link="https://www.instagram.com/p/Chpscl1sHgD/?img_index=1" />
        <BlogCard image={galaxySnorlax} subTitle="Sleeping in spacce" title="Aug 23, 2022" category="sci-fi" link="https://www.instagram.com/p/ChmcR_Vs_C9/" />
        <BlogCard image={blackHole} subTitle="Despair" title="Aug 11, 2022" category="sci-fi" link="https://www.instagram.com/p/ChHaBe6siX9/" />
        <BlogCard image={swimmingToaster} subTitle="Swimming Toaster" title="Apr 14, 2022" category="lowPoly" link="https://www.instagram.com/p/CcTs0QyMjdz/" />
        <BlogCard image={dancing} subTitle="Dancing" title="Apr 6, 2022" category="other" link="https://www.instagram.com/p/CcARCszlOjT/" />
        <BlogCard image={hallwayAlien} subTitle="Sci-fi Hallway" title="Apr 3, 2022" category="sci-fi" link="https://www.instagram.com/p/Cb5aLFuMzl-/" />
        <BlogCard image={car} subTitle="Cars" title="Mar 18, 2022" category="realistic" link="https://www.instagram.com/p/CbOMEv8FUF5/" />
        <BlogCard image={polyCornerStore} subTitle="CornerStore" title="Feb 25, 2022" category="lowPoly" link="https://www.instagram.com/p/CaaJW8UsAlj/" />
        <BlogCard image={building} subTitle="Building" title="N/A" category="realistic" link="#" />
        <BlogCard image={what} subTitle="What" title="Feb 8, 2022" category="abstract" link="https://www.instagram.com/p/CZsaR3DFsvG/" />
        <BlogCard image={mug} subTitle="Mug" title="Feb 6, 2022" category="realistic" link="https://www.instagram.com/p/CZnVLUKsONK/?img_index=1" />
        <BlogCard image={pacman} subTitle="Pacman" title="Feb 4, 2022" category="Realistic" link="https://www.instagram.com/p/CZkVCF9rqWX/" />
        <BlogCard image={animeTemple} subTitle="Anime Temple" title="Feb 3, 2022" category="anime" link="https://www.instagram.com/p/CZhxRB2qsiU/?img_index=1" />
        <BlogCard image={tenseiSlime} subTitle="Rimuru Slime" title="N/A" category="Anime" link="#" />
        <BlogCard image={kaws} subTitle="Kaws" title="N/A" category="toon" link="#" />
        <BlogCard image={animeSky} subTitle="Anime Sky" title="Nov 30, 2021" category="anime" link="https://www.instagram.com/p/CW5O9BUqr23/" />
        <BlogCard image={rain} subTitle="Rain" title="Nov 22, 2021" category="realistic" link="https://www.instagram.com/p/CWl8FFUq4BD/" />
        <BlogCard image={toonBedroom2} subTitle="Toon Bedroom" title="Nov 7, 2021" category="toon" link="https://www.instagram.com/p/CV_StLXqI5X/?img_index=1" />
        <BlogCard image={halloween} subTitle="Halloween" title="Nov 1, 2021" category="holiday" link="https://www.instagram.com/p/CVv0ygEKpQN/" />
        <BlogCard image={halloweenCynda} subTitle="Cyndaquil Halloween" title="Oct 31, 2021" category="holiday" link="https://www.instagram.com/p/CVtMxhSqqRH/" />
        <BlogCard image={halloweenPump} subTitle="Pumpkin Halloween" title="Oct 1, 2021" category="holiday" link="https://www.instagram.com/p/CUf25EQI4JG/" />
        <BlogCard image={screw} subTitle="Screw" title="Sep 27, 2021" category="satisfying" link="https://www.instagram.com/p/CUVBvSBIv_B/" />
        <BlogCard image={hexagon} subTitle="Hexagons" title="Sep 23, 2021" category="satisfying" link="https://www.instagram.com/p/CULA4gdqgOz/" />
        <BlogCard image={rollingBall} subTitle="Rolling Ball" title="Sep 19, 2021" category="satisfying" link="https://www.instagram.com/p/CUBEcRdKFL6/" />
        <BlogCard image={office} subTitle="Office" title="Sep 12, 2021" category="realistic" link="https://www.instagram.com/p/CTspon_qeIR/" />
        <BlogCard image={tubes} subTitle="Tubes" title="June 27, 2021" category="abstract" link="https://www.instagram.com/p/CQmVKheB4SY/" />
        <BlogCard image={toonBedroom} subTitle="Toon Bedroom" title="June 15, 2021" category="toon" link="https://www.instagram.com/p/CQJ3Ivhh0Iy/" />
        <BlogCard image={animeRoad} subTitle="Anime Road" title="June 7, 2021" category="anime" link="https://www.instagram.com/p/CPyx9xPByfY/" />
        <BlogCard image={abstractShapes3} subTitle="Abstract Shapes" title="May 16, 2021" category="asbtract" link="https://www.instagram.com/p/CO8eNr9BVza/" />
        <BlogCard image={asciiStormtrooper} subTitle="May the 4th be with you" title="May 4, 2021" category="other" link="https://www.instagram.com/p/COdTSYABjiH/" />
        <BlogCard image={swirl} subTitle="Jelly Swirl" title="may 2, 2021" category="satisfying" link="https://www.instagram.com/p/COYdr0EBJ06/" />
        <BlogCard image={hand} subTitle="Reaching" title="Apr 1, 2021" category="realistic" link="https://www.instagram.com/p/CNGefikjjwV/" />
        <BlogCard image={skull} subTitle="Forgotten" title="Mar 17,2021" category="realistic" link="https://www.instagram.com/p/CMiNeIoJJxt/?img_index=1" />
        <BlogCard image={toonArcade} subTitle="Arcade Render" title="Mar 11, 2021" category="toon" link="https://www.instagram.com/p/CMR7SffjswX/?img_index=1" />
        <BlogCard image={interiorTardis} subTitle="Interior of Tardis" title="Mar 3, 2021" category="realistic" link="https://www.instagram.com/p/CL-FFr_jN7F/?img_index=1" />
        <BlogCard image={bench} subTitle="Bench" title="Mar 2, 2021" category="realistic" link="https://www.instagram.com/p/CL7lOZKDFSa/" />       
        <BlogCard image={cube} subTitle="Sculpted Cube" title="Mar 1, 2021" category="other" link="https://www.instagram.com/p/CL4_oSzDeEd/" />
        <BlogCard image={killerLego} subTitle="KillerLego" title="Feb 28, 2021" category="realistic" link="https://www.instagram.com/p/CL2ZWF6pdcK/" />
        <BlogCard image={paint} subTitle="Infinte paint" title="Feb 27, 2021" category="realistic" link="https://www.instagram.com/p/CLzywfspP53/" />
        <BlogCard image={lightRays} subTitle="LightRays" title="Feb 26, 2021" category="abstract" link="https://www.instagram.com/p/CLxXS_3JJJx/" />
        <BlogCard image={shapes} subTitle="Triangle Abstract piece" title="Feb 25, 2021" category="abstract" link="https://www.instagram.com/p/CLuoalVBZl-/" />
        <BlogCard image={circuit} subTitle="Circuit" title="Feb 24, 2021" category="other" link="https://www.instagram.com/p/CLsD8AzBsxV/" />
        <BlogCard image={dna} subTitle="Dna" title="Feb 23, 2021" category="procedural" link="https://www.instagram.com/p/CLpdUrABmyW/" />
        <BlogCard image={nightCity} subTitle="NightCity" title="Feb 20, 2021" category="realistic" link="https://www.instagram.com/p/CLh3psGhU7I/" />
        <BlogCard image={wrap} subTitle="Shrink Wrap" title="Feb 18,2021" category="other" link="https://www.instagram.com/p/CLcj6dEB4Xb/" />
        <BlogCard image={abstractShape2} subTitle="Caption" title="Feb 17, 2021" category="abstract" link="https://www.instagram.com/p/CLaF49dBSSg/" />
        <BlogCard image={tardis} subTitle="Tardis" title="Feb 15, 2021" category="realistic" link="https://www.instagram.com/p/CLU708uBcQ0/" />
        <BlogCard image={abstractSphere} subTitle="Displacment Ball" title="Feb 14, 2021" category="abstract" link="https://www.instagram.com/p/CLSQuxwhiih/" />
        <BlogCard image={abstractShapes} subTitle="Abstract Shapes" title="Feb 13, 2021" category="abstract" link="https://www.instagram.com/p/CLNVfCxBAgC/" />
        <BlogCard image={spider} subTitle="Procedural spider ball" title="Feb 11, 2021" category="procedural" link="https://www.instagram.com/p/CLKjJ7hBaG7/" />
        <BlogCard image={fluffBalls} subTitle="Fluff Balls" title="Feb 10, 2021" category="realistic" link="https://www.instagram.com/p/CLH0oLEBABu/" />
        <BlogCard image={earth} subTitle="The Earth" title="Feb 8, 2021" category="Realistic" link="https://www.instagram.com/p/CLB9VceBjAs/" />
        <BlogCard image={donut} subTitle="Real or Fake?? Donut??" title="Feb 6, 2021" category="realistic" link="https://www.instagram.com/p/CK9X3SkB9PV/" />
      </div>
    </div>
  );
};

export default Blog;
