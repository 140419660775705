import React, { useState } from "react";
import { imperialOverlay } from "../../assets";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

const Modal = ({ isOpen, onClose, content }) => {
  // State to track the current page
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Initialize the toolbar plugin
  const toolbarPluginInstance = toolbarPlugin();

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto"
      onClick={onClose} // Close modal when clicking on the background
    >
      <div
        className="nes-container is-dark relative bg-white p-6 w-11/12 max-w-5xl max-h-full overflow-y-auto"
        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside the modal
      >
        <button
          className="absolute top-4 right-4 text-2xl text-white border border-white z-50"
          onClick={onClose}
          style={{
            backgroundColor: "#212529",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            outline: "none",
          }}
        >
          &times;
        </button>

        <div className="flex flex-col md:flex-row w-full h-full md:space-x-4">
          <div className="nes-container is-dark w-full md:w-2/5 mb-4 md:mb-0">
            <div className="mt-4">
              <div className="relative">
                <img
                  src={content.image}
                  alt={content.title}
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                {content.showOverlay && (
                  <img
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full h-auto z-10"
                    src={imperialOverlay}
                    alt="Overlay"
                  />
                )}
              </div>
              <h2 className="text-2xl font-semibold mb-2">{content.title}</h2>
              <p className="text-gray-700">{content.category}</p>
              <p className="text-gray-600 mt-2 mb-4">{content.description}</p>

              {/* Download Button */}
              {content.downloadLink && (
                <a
                  href={content.downloadLink}
                  download
                  className="nes-btn is-success justify-center py-2 px-4 hover:bg-green-700 transition-colors mt-4"
                >
                  Download File
                </a>
              )}
            </div>
          </div>

          <div className="nes-container is-dark w-full md:w-3/5 flex flex-col items-center justify-center">
            {content.downloadLink && (
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer
                  fileUrl={content.downloadLink}
                  plugins={[toolbarPluginInstance]}
                  pageIndex={currentPage} // Display only the current page
                  onDocumentLoad={({ doc }) => setTotalPages(doc.numPages)}
                />
              </Worker>
            )}
            <div className="flex space-x-2 mt-4">
              <button
                onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
                disabled={currentPage === 0}
                className="nes-btn"
              >
                Previous Page
              </button>
              <button
                onClick={() =>
                  setCurrentPage(Math.min(currentPage + 1, totalPages - 1))
                }
                disabled={currentPage === totalPages - 1}
                className="nes-btn"
              >
                Next Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
