import React, { useState, useEffect } from "react";

const AboutMe = () => {
  const [age, setAge] = useState(0);

  useEffect(() => {
    function calculateAge(birthdate) {
      const diff_ms = Date.now() - birthdate.getTime();
      const age_dt = new Date(diff_ms);

      return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    const birthdate = new Date("2004-09-18");
    setAge(calculateAge(birthdate));
  }, []);

  return (
    <div className="nes-container is-dark with-title">
      <p className="title">About Me</p>
    <div className="flex flex-col md:flex-row pb-6">
      <div className="w-full md:w-1/2 text-zinc-400 px-6 border-r-[1px] border-r-zinc-800 flex items-center">
        <div className="py-6">
          <h2 className="nes-text is-primary font-semibold mb-1">Hi! I'm Harry</h2>
          <p className="text-base leading-6">
          Imperial College Design Engineering Student, Advanced at 3D modelling, coding, web design, prototyping, and more.  </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-6">
          <ul className="nes-list is-disc">
            <li className="font-bodyfont text-sm nes-text">
              <button className="font-bodyfont text-base nes-btn is-primary" style={{ pointerEvents: 'none' }}>Age:</button> {age}
            </li>
            <li className="font-bodyfont text-sm nes-text">
              <button className="font-bodyfont text-base nes-btn is-primary" style={{ pointerEvents: 'none' }}>Residence:</button> UK
            </li>
            <li className="font-bodyfont text-sm nes-text" style={{ whiteSpace: 'nowrap' }}>
              <button className="font-bodyfont text-base nes-btn is-primary" style={{ pointerEvents: 'none' }}>Freelance:</button> Available
            </li>
            <li className="font-bodyfont text-xs nes-text" style={{ whiteSpace: 'nowrap' }}>
              <button className="font-bodyfont text-base nes-btn is-primary" style={{ pointerEvents: 'none' }}>Address:</button> South Kensington
            </li>
          </ul>
        </div>
    </div>
    </div>
  );
};

export default AboutMe;