import React from "react";
// import Title from "../home/Title";
import AboutMe from "./AboutMe";
import FunFact from "./FunFact";
import MyServices from "./MyServices";

const About = () => {
  return (
    <section id="about" className="w-full">
      <AboutMe />
      {/* <Title title="My" subTitle="specialities" /> */}
      <br></br>
      <MyServices />
      <br></br>
      {/* <Title title="Fun" subTitle="Facts" /> */}
      <FunFact />
      <br></br>
    </section>
  );
};

export default About;