import React from "react";
import { SiArtstation } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
// import { IoIosPaper } from "react-icons/io";
// import { GiCheckMark } from "react-icons/gi";
import ResumeTitle from "./ResumeTitle";

const Skills = () => {
  return (
    <div className="nes-container is-dark with-title">
      <p className="title">My Skills</p>
      <div className="w-full grid grid-cols-9 gap-10 lgl:gap-0 px-6">
        <div className="col-span-9 md:col-span-4">
          <ResumeTitle title="Design" icon={<SiArtstation className="nes-icon is-medium" />} />
          <div className="py-4">
            <div className="py-3">
              <p className="nes-text text-base">3D - Artist</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="85" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">3D - Designer</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="90" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">Front end Web Design</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="80" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">Back end Web Desgn</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="70" max="100"></progress>
            </div>
          </div>
        </div>

        <div className="w-full h-full hidden lgl:flex justify-center items-center">
          <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
        </div>

        <div className="col-span-9 md:col-span-4">
          <ResumeTitle title="Coding" icon={<BiCodeAlt className="nes-icon is-medium" />} />
          <div className="py-4">
            <div className="py-3">
              <p className="nes-text text-base">HTML</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="95" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">CSS</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="95" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">JavaScript</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="80" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">JSON</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="80" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">Python</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="75" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">C++</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="70" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">PHP</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="60" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">SQL</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="40" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">C#</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="40" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">React</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="35" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">NodeJS</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="30" max="100"></progress>
            </div>
            <div className="py-3">
              <p className="nes-text text-base">GDScript</p>
              <progress className="nes-progress is-primary" style={{ height: '12px', border: '2px solid white' }} value="40" max="100"></progress>
            </div>
          </div>
        </div>

        {/* <div className="col-span-9 md:col-span-4">
          <ResumeTitle title="Knowledge" icon={<IoIosPaper className="nes-icon is-medium" />} />
          <ul className="py-4 flex flex-col gap-2">
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
            <li className="flex items-center gap-4">
              <GiCheckMark className="nes-icon is-small text-designColor" />
              <span className="nes-text"></span>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Skills;
