import React from "react";

const BlogCard = ({ image, title, subTitle, category, link }) => {
  const isVideo = image.endsWith(".mp4");

  return (
    <div className="py-6 flex flex-col gap-2 items-center justify-center border-b-[1px] border-b-zinc-800 group">
      {isVideo ? (
        <video controls className="w-full h-auto">
          <source src={image} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img src={image} alt={subTitle} className="w-full h-auto" />
      )}
      <div className="flex flex-col items-center gap-2">
        <span className="text-sm text-designColor border-[1px] border-designColor px-2">
          {title}
        </span>
        <a
          href={link}
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
        >
          <h2 className="text-base text-center font-semibold font-titleFont text-[#ccc] group-hover:text-designColor duration-300">
            {subTitle}
          </h2>
        </a>
        <p className="text-sm -mt-1 text-gray-400">{category}</p>
      </div>
    </div>
  );
};

export default BlogCard;
