import React from "react";
import PixelCity from "./assets/PixelCity.mp4";
import Home from "./Home";

function App() {
  return (
    <div className="w-full lgl:h-screen font-bodyfont overflow-hidden text-textColor relative">
      {/* Background Video */}
    <video
      autoPlay
      loop
      muted
      className="absolute top-0 left-0 w-full h-full object-cover z-0"
      style={{ willChange: "transform" }}
    >
      <source src={PixelCity} type="video/mp4" />
      Your browser does not support the video tag.
    </video>


      {/* Main Content (Home Component) */}
      <div className="max-w-screen-2xl h-full mx-auto flex justify-center items-center">
        <Home />
      </div>
      
    </div>



    
  );
}

export default App;


