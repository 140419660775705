import React from "react";
// import Title from "../home/Title";
import Education from "./Education";
import Skills from "./Skills";

const Resume = () => {
  return (
    <section id="resume">
      {/* <Title title="My" subTitle="Resume" /> */}
      <Education />
      <br></br>
      {/* <Title title="My" subTitle="Skills" /> */}
      <Skills />
      <br></br>
    </section>
  );
};

export default Resume;