import React from "react";
import { FaAppStoreIos } from "react-icons/fa";
import { AiTwotoneAppstore } from "react-icons/ai";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import ServicesCard from "./ServicesCard";

const MyServices = () => {
  return (
    <div className="nes-container with-title is-dark">
      <p className="title">My Specialities</p>
     <div className=" grid grid-cols-1 md:grid-cols-2">
      <ServicesCard
        icons={<AiTwotoneAppstore className="nes-icon is-medium" />}
        title="3D modelling"
        subTitle="Advanced knowledge in 3D modelling softwars like Blender and Fusion360; can quickly  prototype designs and models for stress testing, practical appliances, and clients."
      />
      <ServicesCard
        icons={<SiAntdesign />}
        title="Web Design"
        subTitle="Can struggle to positions divs for hours"
      />
      <ServicesCard
        // icons={<AiTwotoneAppstore />}
        icons={<BiCodeAlt />}
        title="Good at not knowing what to type"
        subTitle="Will read my own cv one day and fill these in"
      />
      <ServicesCard
        icons={<FaAppStoreIos />}
        title="Hmmmm"
        subTitle="Yes, i do plan to finish these eventually, the rest of the webstite and the UI is pretty impressive in my opinion though."
      />
      </div>
    </div>
  );
};

export default MyServices;
