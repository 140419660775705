import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import CV from "../../assets/HarryWarriner_CV.pdf";
import { bannerImg } from "../../assets/index";

const Left = () => {
  const [text] = useTypewriter({
    words: ["Design Engineer", "3D Artist", "Programmer"],
    loop: true,
    typeSpeed: 30,
    deleteSpeed: 20,
    delaySpeed: 2000,
  });
  return (
    <div className="nes-container with-title is-dark w-full lgl:w-5/12 h-full">
      <p className="title">Overview</p>
      <div className="w-full h-1/2">
        <img
          className="w-full h-full object-cover"
          src={bannerImg}
          loading="priority"
          alt="bannerImage"
        />
      </div>
      <div className="w-full h-2/5 flex flex-col justify-between">
        <div className="flex flex-col items-center gap-2 py-10">
          <h1 className="nes-text is-primary text-2xl">Harry Warriner</h1>
          <p className="nes-text">
            {text}
            <Cursor cursorBlinking="false" cursorStyle="|" />
          </p>
          <div className="flex justify-center gap-2 mt-2">
          <a
              href="https://github.com/HarryWarriner"
              target="_blank"
              rel="noreferrer"
              className="nes-icon github is-medium"
            >
            
            </a>
            <a
              href="https://www.linkedin.com/in/harrywarriner/"
              target="_blank"
              rel="noreferrer"
              className="nes-icon linkedin is-medium"
            >
             
            </a>
            <a
              href="https://instagram.com/renderkai"
              target="_blank"
              rel="noreferrer"
              className="nes-icon instagram is-medium"
            >
           
            </a>
            <a
              href="mailto:your-email@example.com"
              className="nes-icon gmail is-medium"
            >
            
            </a>
          </div>
        </div>
        <div className="flex h-14 items-center justify-center">
          <a
            href={CV}
            target="_blank"
            className="w-auto nes-btn is-primary flex items-center justify-center"
            rel="noreferrer"
          >
            Download CV
          </a>
          {/* <button className="w-1/2 nes-btn is-warning flex items-center justify-center">
            Contact me
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Left;